import React from 'react'

import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO/SEO'
import PricingTable from '../components/PricingTable/PricingTable'
import QuestionList from '../components/QuestionList/QuestionList'
import Contact from '../components/Contact/Contact'

const PricingPage = () => (
  <Layout>
    <SEO title="LodaStack 监控系统价格方案" />
    <PricingTable />
    <QuestionList />
    <Contact />
  </Layout>
)

export default PricingPage
