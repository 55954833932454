import React from 'react'
import styles from './Contact.module.css'

const Contact = () => (
  <div id="contact" className={styles.container}>
    <div className="wrapper">
      <h2>联系我们</h2>
      <div className={styles.grid}>
        <div className={styles.cell}>
          <h4>发送邮件至 <a href="mailto:sales@lodastack.com">sales@lodastack.com</a></h4>
        </div>
      </div>
    </div>
  </div>
)

export default Contact
