import React, { useState } from 'react'
import classNames from 'classnames'
import styles from './PricingTable.module.css'

const PricingTable = () => {
  const data = [
    {
      category: 'cloud',
      title: 'Loda Cloud',
      packages: [
        {
          title: '基础版',
          count: [1, 100],
          price: 58,
          unit: '万',
          period: '年',
        },
        {
          title: '专业版',
          count: [101, 500],
          price: 88,
          unit: '万',
          period: '年',
        },
        {
          title: '旗舰版',
          count: [501, 2000],
          price: 168,
          unit: '万',
          period: '年',
        },
        {
          title: '企业版',
          count: [2001, 7000],
          price: 208,
          unit: '万',
          period: '年',
        },
        {
          title: '更多',
          count: [7001],
          price: 0,
          unit: '万',
          period: '年',
        },
      ]
    },
    {
      category: 'host',
      title: '私有化部署',
      packages: [
        {
          title: '请联系我们',
          count: [11],
          price: 0,
          unit: '万',
          period: '年',
        },
		{
          title: '专业团队',
          count: [11],
          price: 0,
          unit: '万',
          period: '年',
        }
      ]
    }
  ]
  const [active, setActive] = useState(0)
  const tabPanes = data.map((datum, index) => {
    const cls = classNames({
      [styles.tabPane]: 1,
      [styles.tabPaneActive]: active === index
    })
    return (
      <div className={cls} key={index}>
        <div className={styles.packageList}>
          {datum.packages.map((item, index) => {
            if (item.price === 0) {
              return (
                <div className={styles.card}>
                  <h4 className={styles.title}>{item.title}</h4>
                  <div className={styles.count}>
                    大于 {item.count[0] - 1} 台服务器
                  </div>
                  <p><a href="#contact">联系我们</a></p>
                </div>
              )
            }
            return (
              <div className={styles.card}>
                <h4 className={styles.title}>{item.title}</h4>
                <div className={styles.price}>
                  <span className={styles.currency}>￥</span>
                  <span className={styles.value}>{item.price}</span>
                  <span className={styles.unit}>{item.unit}</span>
                  /
                  <span className={styles.period}>{item.period}</span>
                </div>
                <div className={styles.count}>
                  {item.count[0]}~{item.count[1] || ''} 台服务器
                </div>
                <ul className={styles.list}>
                  {item.emailSupport &&
                    <li>
                      <i className="lsicon ls-icon-email"></i>
                      邮件支持
                    </li>}
                  {item.chatSupport &&
                    <li>
                      <i className="lsicon ls-icon-chat"></i>
                      即时聊天支持
                    </li>}
                </ul>
              </div>
            )
          })}
        </div>
      </div>
    )
  })
  return (
    <div className={styles.container}>
      <div className="wrapper">
        <h1>LodaStack 监控服务价格方案</h1>
        <div className={styles.tabs}>
          <div className={styles.tabsHeader}>
            <ul className={styles.tabNav}>
              {data.map((datum, index) => {
                const cls = classNames({
                  [styles.tabNavItem]: 1,
                  [styles.tabNavItemActive]: active === index
                })
                return (
                  <li
                    className={cls}
                    key={index}
                    onClick={() => setActive(index)}
                  >
                    {datum.title}
                  </li>
                )
              })}
            </ul>
          </div>
          <div className={styles.tabsBody}>
            {tabPanes}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingTable
