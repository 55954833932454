import React, { useState } from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import styles from './QuestionList.module.css'

const QuestionList = () => {
  const rawData = [
    {
      question: '对服务器配置有要求吗？',
      answer: `
        没有要求，lodastack agent 占用内存极小，无论低至 1核0.5G 还是 128核512G，
        甚至更高配置均可以，且均算作一台服务器费用。
      `
    },
    {
      question: '支持哪些公有云？',
      answer: `
        所有公有云和私有云都支持，只要可以运行 Linux 或 Windows 系统的服务器都支持。
      `
    },
    {
      question: '监控的数据有哪些？',
      answer: `
        除了核心的 CPU、内存、文件系统、磁盘、网络、内核、进程之外，还提供了丰富的插件，
        如果插件不能满足要求，还可以通过<a href="/docs/custom-metrics.html">自定义上报</a>
        来上报任意数据。
      `
    },
    {
      question: '支持 GPU 服务器监控吗？',
      answer: `
        支持，可以查看文档 <a href="/docs/GPU-device-monitoring.html">GPU 服务器监控</a>。
      `
    },
    {
      question: '对于 IPv6 支持吗？',
      answer: `
        支持，我们的监控系统自身可以完全运行在一个
        <a href="/docs/IPv6-adoption.html">IPv6 网络环境</a>，
        对于 IPv6 相关的业务逻辑也完全适配。
      `
    }
  ]
  // TODO: immutablejs?
  const [data, setData] = useState(rawData)

  const toggle = (index) => {
    const nextData = _.cloneDeep(data)
    nextData[index].active = !nextData[index].active
    setData(nextData)
  }
  const list = data.map((datum, i) => {
    const cls = classNames({
      [styles.item]: 1,
      [styles.active]: datum.active
    })
    return (
      <li className={cls} key={i}>
        <p className={styles.question} onClick={() => toggle(i)}>
          <strong>{datum.question}</strong>
        </p>
        <div
          className={styles.answer}
          dangerouslySetInnerHTML={{ __html: `<p>${datum.answer}</p>` }}
        />
      </li>
    )
  })
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>常见问题</h2>
        <ul className={styles.list}>
          {list}
        </ul>
      </div>
    </div>
  )
}

export default QuestionList